body {
  background-color: gray;
}
.navbar {
  width: 100%;
}

.navbar-brand {
    font-family: 'Amatic SC', cursive;
    
  
  }

  .nav-link {
    font-family: 'Amatic SC', cursive;
  }

  .card-title {
    font-family: 'Dancing Script', cursive;
  }
.product-page {
  padding-top: 4rem;
  
}
.container {
  width: 100%;
}
  .product-card {
    width: 18rem;
   margin-left: 2rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: rgb(147, 147, 228);
  }

  .prod-card {
    top: 5.5rem;
  }

  form {
    text-align: center;
  }

  table {
    margin-right: auto;
    margin-left: auto;
    
  }

  select {
    width: 75%;
  }
 
  .house {
    display: block;
    position: absolute;
    right:0;
    
  }

  .email-button {
   text-align: center;
   margin: 25px;
   
  }

  @media only screen and (max-width: 768px) {
  
.table-option {
  width: 75%;
}
  .container {
    width: 75%;
  }

  }
  
  @media only screen and (max-width: 40em) {
  .table-option {
    width: 50%;
  }
 .card-body p {
   font-size: 1rem;
 }
  }
